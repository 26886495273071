var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ls-seckill-edit"},[_c('div',{staticClass:"ls-card ls-seckill-edit__header"},[_c('el-page-header',{attrs:{"content":_vm.id
                    ? _vm.disabled
                        ? '秒杀活动详情'
                        : '编辑秒杀活动'
                    : '新增秒杀活动'},on:{"back":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"ls-seckill-content"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"model":_vm.formData,"label-width":"120px","size":"small","rules":_vm.rules,"disabled":_vm.disabled}},[_c('div',{staticClass:"ls-card m-t-16"},[_c('div',{staticClass:"nr weight-500 m-b-20"},[_vm._v("活动设置")]),_c('el-form-item',{attrs:{"label":"活动名称","prop":"name"}},[_c('el-input',{staticClass:"ls-input",attrs:{"placeholder":"请输入活动名称"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('el-form-item',{attrs:{"label":"活动时间","prop":"start_time"}},[_c('date-picker',{attrs:{"type":"datetimerange","start-time":_vm.formData.start_time,"end-time":_vm.formData.end_time},on:{"update:startTime":function($event){return _vm.$set(_vm.formData, "start_time", $event)},"update:start-time":function($event){return _vm.$set(_vm.formData, "start_time", $event)},"update:endTime":function($event){return _vm.$set(_vm.formData, "end_time", $event)},"update:end-time":function($event){return _vm.$set(_vm.formData, "end_time", $event)}}}),_c('div',{staticClass:"muted"},[_vm._v(" 秒杀活动开始和结束时间，可以手动提前结束活动 ")])],1),_c('el-form-item',{attrs:{"label":"活动备注"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"textarea","rows":5,"placeholder":""},model:{value:(_vm.formData.explain),callback:function ($$v) {_vm.$set(_vm.formData, "explain", $$v)},expression:"formData.explain"}})],1)],1),_c('div',{staticClass:"ls-card m-t-16"},[_c('div',{staticClass:"nr weight-500 m-b-20"},[_vm._v("活动商品")]),_c('el-form-item',{attrs:{"label":"秒杀商品","required":""}},[_c('goods-select',{attrs:{"mode":"table","is-spec":true,"limit":25,"extend":{
                            name: '秒杀',
                            price: [
                                {
                                    title: '秒杀价格',
                                    key: 'seckill_price'
                                }
                            ]
                        }},model:{value:(_vm.selectGoods),callback:function ($$v) {_vm.selectGoods=$$v},expression:"selectGoods"}},[_c('el-button',{attrs:{"size":"mini","type":"primary"}},[_vm._v("选择秒杀商品")])],1)],1)],1),_c('div',{staticClass:"ls-card m-t-16"},[_c('div',{staticClass:"nr weight-500 m-b-20"},[_vm._v("活动规则")]),_c('el-form-item',{attrs:{"label":"每单限制","required":""}},[_c('el-radio-group',{model:{value:(_vm.formData.max_buy),callback:function ($$v) {_vm.$set(_vm.formData, "max_buy", $$v)},expression:"formData.max_buy"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("不限制")]),_c('el-radio',{attrs:{"label":_vm.formData.max_buy != 0
                                    ? _vm.formData.max_buy
                                    : ''}},[_vm._v("限制")])],1)],1),(_vm.formData.max_buy !== 0)?_c('el-form-item',{attrs:{"prop":"max_buy","label":""}},[_c('el-input',{attrs:{"type":"number"},on:{"input":function($event){return _vm.handleInput($event, 'max_buy')}},model:{value:(_vm.formData.max_buy),callback:function ($$v) {_vm.$set(_vm.formData, "max_buy", $$v)},expression:"formData.max_buy"}}),_c('div',{staticClass:"muted"},[_vm._v(" 每件商品单笔订单最多购买的件数。 ")])],1):_vm._e()],1)])],1),_c('div',{staticClass:"ls-seckill-edit__footer bg-white ls-fixed-footer"},[_c('div',{staticClass:"btns row-center flex",staticStyle:{"height":"100%"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary","disabled":_vm.disabled},on:{"click":_vm.handleSave}},[_vm._v("保存")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }