






























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import GoodsSelect from '@/components/goods-select/index.vue'
import DatePicker from '@/components/date-picker.vue'
import {
    apiSeckillAdd,
    apiSeckillDetail,
    apiSeckillEdit
} from '@/api/marketing/seckill'
@Component({
    components: {
        MaterialSelect,
        DatePicker,
        GoodsSelect
    }
})
export default class AddBrand extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    disabled = false
    formData: any = {
        name: '',
        explain: '',
        start_time: '',
        end_time: '',
        goods: [],
        max_buy: 0
    }
    selectGoods = []
    rules = {
        name: [
            {
                required: true,
                message: '请输入活动名称',
                trigger: ['blur']
            }
        ],
        start_time: [
            {
                required: true,
                message: '选择活动时间',
                trigger: 'blur'
            }
        ],

        max_buy: [
            {
                required: true,
                message: '请输入每单限制数量',
                trigger: 'blur'
            }
        ]
    }
    @Watch('selectGoods', { deep: true })
    selectGoodsChange(val: any[]) {
        this.formData.goods = val.map((item: any) => {
            return {
                goods_id: item.id,
                items: item.item.map((sitem: any) => ({
                    item_id: sitem.id,
                    seckill_price: sitem.seckill_price
                }))
            }
        })
    }
    checkGoods() {
        const goods = this.formData.goods
        if (!goods.length) {
            this.$message.error('请选择秒杀商品')
            return false
        }
        for (let i = 0; i < goods.length; i++) {
            for (let j = 0; j < goods[i].items.length; j++) {
                if (!goods[i].items[j].seckill_price) {
                    this.$message.error(`请输入商品秒杀价`)
                    return false
                }
            }
        }
        return true
    }
    handleInput(val: any, type: string) {
        if (val <= 0 && val !== '') {
            this.formData[type] = 1
        }
    }
    handleSave() {
        this.$refs.form.validate((valid: boolean, object: any) => {
            if (valid) {
                if (!this.checkGoods()) {
                    return
                }
                const api = this.id
                    ? apiSeckillEdit(this.formData)
                    : apiSeckillAdd(this.formData)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }

    getDetail() {
        this.loading = true
        apiSeckillDetail({ id: this.id }).then((res: any) => {
            this.formData = res
            this.selectGoods = res.goods
            this.loading = false
        })
    }

    created() {
        this.id = this.$route.query.id
        this.disabled = Boolean(this.$route.query.disabled)
        this.id && this.getDetail()
    }
}
